<template>
  <div class="base-assigment-modal">
    <b-modal
      v-model="showAssigmentModal"
      @hide="hideAssigmentModal"
      class="gmv-upload-modal-container"
      id="uploadModal"
      scrollable
      title="Suba su archivo Base Asignación"
    >
      <div
        v-loading="Loading"
        element-loading-text="Procesando..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(245 , 229, 229, 0.8)"
        style="width: 100%"
      >
        <b-form
          id="form"
          name="base-assigment-modal__form"
          v-on:submit.prevent=""
        >
          <b-card-text>
            <div class="inputs">
              <label class="inputs__title">Archivos</label>
              <div class="inputs__grid">
                <b-input
                  required
                  title="Este campo es obligatorio, solo números"
                  aria-label="required"
                  name="campaña"
                  autocomplete="off"
                  size="sm"
                  type="number"
                  class="gmv-process-modal-form__inputs"
                  v-model="campaña"
                  placeholder="Campaña*"
                ></b-input>
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="asignacion"
                  autocomplete="off"
                  size="sm"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processAssigmentBase.assignment_code"
                  placeholder="Codigo asignación*"
                ></b-input>
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  autocomplete="off"
                  size="sm"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processAssigmentBase.assignment_path"
                  placeholder="Ruta asignación*"
                ></b-input>
              </div>
            </div>
            <hr />
            <div class="gmv-process-modal-button"></div
          ></b-card-text>
        </b-form>
        <div class="inputs">
          <label class="inputs__title">Ruta asignación</label>
          <FileUpload
            :auto="false"
            accept=".xlsx,.prt,.xlsb,.txt,.csv"
            :maxFileSize="100000000"
            @select="filesChange"
            @clear="clearFile"
            :fileLimit="1"
            chooseLabel="haz clic para cargar"
            :showCancelButton="false"
            :showUploadButton="false"
          >
            <template #empty>
              <i class="el-icon-upload fa-6x" style="color: #b3282d"></i>
              <div class="el-upload__text">Suelta tu archivo aquí</div>
            </template>
          </FileUpload>
        </div>
        <div slot="tip" class="el-upload__tip">
          Solo archivos XLSB, XLSX, TXT, CSV con un tamaño menor a 100Mb
        </div>
        <div
          class="process-button__content"
          v-if="showDeallocationUploadButton"
        >
          <Button
            label="Subir archivo"
            class="gmv-uploadmodal-button__btn1"
            @click="filesAssigmentChange"
          />
        </div>
        <div class="process-button__content" v-if="showAssigmentProcessButton">
          <Button
            class="upload-button"
            type="submit"
            @click="BaseAssigmentProcess"
            >Procesar
          </Button>
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-upload-modal-button"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import { Loading } from "element-ui";
import Button from "primevue/button";

export default {
  inject: ["reload"],
  name: "BaseAssigmentModal",
  props: {
    openAssigmentModal: Boolean,
  },
  components: {
    Button,
    FileUpload,
    Loading,
  },
  data() {
    return {
      campaña: null,
      files: [],
      showAssigmentModal: false,
      processAssigmentBase: {
        campaña: "",
        assignment_code: "",
        assignment_path: "",
      },
      Loading: false,
      showAssigmentProcessButton: false,
      showDeallocationUploadButton: true,
    };
  },
  watch: {
    openAssigmentModal() {
      this.openAssigmentModal == true
        ? (this.showAssigmentModal = true)
        : (this.openAssigmentModal = false);
    },
    processAssigmentBase: {
      deep: true,
      handler() {
        this.formAssigmentValidation();
      },
    },
  },
  methods: {
    hideAssigmentModal() {
      this.showAssigmentProcessButton = false;
      this.showDeallocationUploadButton = true;
      for (let i in this.processAssigmentBase) {
        this.processAssigmentBase[i] = "";
      }
      this.$emit("closeAssigmentModal");
    },
    filesChange(fileList) {
      fileList.files.forEach((file) => {
        this.files.push(file);
      });
    },
    clearFile() {
      this.files = [];
    },
    async filesAssigmentChange() {
      let data = new FormData();
      this.files.forEach((file) => {
        data.append("file", file);
      });
      const campaña = this.campaña;
      let result = await this.$store.dispatch("upload/uploadFilesSantander", {
        data,
        campaña,
        selectedOption: "asignaciones",
      });
      this.processAssigmentBase.assignment_path = result.data.directory;
    },
    formAssigmentValidation() {
      let count = 0;
      for (let i in this.processAssigmentBase) {
        if (this.processAssigmentBase[i] != "") {
          count += 1;
        }
      }
      count == 2 ? (this.showAssigmentProcessButton = true) : "";
      count == 2 ? (this.showDeallocationUploadButton = false) : "";
    },
    async BaseAssigmentProcess() {
      this.Loading = true;
      let body = {};
      body.assignment_code = this.processAssigmentBase.assignment_code;
      body.assignment_path = this.processAssigmentBase.assignment_path;
      await this.$store.dispatch("processBase/postAssigmentBase", body);
      this.Loading = false;
      this.reload({ name: "ReportedFiles" });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  &__title {
    color: #717274;
    margin-bottom: 0;
  }
  &__grid {
    display: flex;
    margin: 10px;
  }
}
.gmv-process-modal-form {
  &__inputs {
    margin: 10px;
  }
}
.process-button__content {
  display: flex;
  justify-content: right;
}
.gmv-uploadmodal-button {
  display: flex;
  justify-content: space-evenly;
  &__btn1 {
    background-color: $color-primary-company;
    height: 45px;
    border: 1px solid $color-primary-company;
    width: 150px;
    border-radius: 10px;
    margin: 10px;
  }
  &__btn1:hover {
    background-color: red;
    color: black;
    border: 1px solid $color-primary-company;
  }
}
.button-upload-files {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@include upload-button;
.gmv-process-modal-button {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
::v-deep .p-fileupload-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 45%;
}
::v-deep .p-fileupload .p-fileupload-content {
  padding: 4rem 4rem;
  border: 2px dashed #dee2e6;
  color: #495057;
  border-radius: 10px;
  &:hover {
    border: 2px dashed #b3282d;
  }
}
::v-deep .p-fileupload-choose:not(.p-disabled) {
  background: transparent;
  color: #b3282d;
  border-color: transparent;
  &:hover {
    background: transparent;
    color: #fd040c;
    border-color: transparent;
  }
}
::v-deep .p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
  padding: 0;
}
::v-deep .modal-footer {
  display: none;
}
::v-deep .el-upload__tip {
  font-size: 14px;
  color: #606266;
  margin-top: 7px;
}
</style>
