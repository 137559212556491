<template>
  <div class="base-deallocation-modal">
    <b-modal
      v-model="showDeallocationModal"
      @hide="hideDeallocationModal"
      class="gmv-upload-modal-container"
      id="uploadModal"
      scrollable
      title="Suba su archivo Base actualización"
    >
      <div
        v-loading="Loading"
        element-loading-text="Procesando..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(245 , 229, 229, 0.8)"
        style="width: 100%"
      >
        <b-form
          id="form"
          name="base-deallocation-modal__form"
          v-on:submit.prevent=""
        >
          <b-card-text>
            <div class="inputs">
              <label class="inputs__title">Campañas</label>
              <div class="inputs__grid">
                <b-input
                  required
                  title="Este campo es obligatorio, solo números"
                  aria-label="required"
                  name="campaña"
                  autocomplete="off"
                  size="sm"
                  type="number"
                  class="gmv-process-modal-form__inputs"
                  v-model="campaña"
                  placeholder="Campaña*"
                ></b-input>
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  name="asignacion"
                  autocomplete="off"
                  size="sm"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processDeallocationBase.assignment_code"
                  placeholder="Codigo asignación*"
                ></b-input>
                <b-input
                  required
                  title="Este campo es obligatorio, solo números"
                  aria-label="required"
                  name="campaña"
                  autocomplete="off"
                  size="sm"
                  type="number"
                  class="gmv-process-modal-form__inputs"
                  v-model="processDeallocationBase.campaign_2"
                  placeholder="Campaña anterior"
                ></b-input>
              </div>
            </div>
            <div class="inputs">
              <label class="inputs__title">Archivos</label>
              <div class="inputs__grid">
                <b-form-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  class="gmv-process-modal-form__inputs"
                  size="sm"
                  autocomplete="off"
                  placeholder="Ruta actualización*"
                  v-model="processDeallocationBase.updates_path"
                  id="input-with-list"
                ></b-form-input>
                <b-input
                  required
                  title="Este campo es obligatorio"
                  aria-label="required"
                  size="sm"
                  autocomplete="off"
                  type="text"
                  class="gmv-process-modal-form__inputs"
                  v-model="processDeallocationBase.desassignment_path"
                  placeholder="Ruta desasignación*"
                ></b-input>
                <b-form-select
                  size="sm"
                  required
                  title="seleccione una ruta asignación"
                  aria-label="required"
                  :options="filesAsignacion"
                  class="gmv-process-modal-form__inputs"
                  v-model="processDeallocationBase.assignment_path"
                >
                  <template #first>
                    <b-form-select-option disabled
                      >--Por favor seleccione una ruta asignación
                      --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </div>
            </div>
            <hr />
            <div class="gmv-process-modal-button"></div
          ></b-card-text>
        </b-form>
        <div class="inputs">
          <label class="inputs__title">Ruta desasignación</label>
          <FileUpload
            :auto="false"
            accept=".xlsx,.prt,.xlsb,.txt,.csv"
            :maxFileSize="100000000"
            @select="filesChange"
            @clear="clearFile"
            :fileLimit="1"
            chooseLabel="haz clic para cargar"
            :showCancelButton="false"
            :showUploadButton="false"
          >
            <template #empty>
              <i class="el-icon-upload fa-6x" style="color: #b3282d"></i>
              <div class="el-upload__text">Suelta tu archivo aquí</div>
            </template>
          </FileUpload>
        </div>
        <div slot="tip" class="el-upload__tip">
          Solo archivos XLSB, XLSX, TXT, CSV con un tamaño menor a 100Mb
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-upload-modal-button"></div>
      </template>
      <hr />
      <div class="button-upload-files">
        <el-radio-group v-model="parametersSantander">
          <el-radio
            v-for="(querySantander, index) in queriesSantander"
            :label="querySantander"
            :key="index"
            >{{ querySantander.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="process-button__content" v-if="showDeallocationUploadButton">
        <Button
          label="Subir archivo"
          class="gmv-uploadmodal-button__btn1"
          @click="filesDeallocationChange"
        />
      </div>
      <div class="process-button__content" v-if="showDeallocationProcessButton">
        <Button
          class="upload-button"
          type="submit"
          @click="BaseDeallocationProcess"
          >Procesar
        </Button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import { Loading } from "element-ui";
import Button from "primevue/button";
import queryVariablesSantander from "@/utils/uploaderVariablesSantander";

export default {
  inject: ["reload"],
  name: "BaseDeallocationModal",
  props: {
    openDeallocationModal: Boolean,
    bankName: Array,
  },
  components: {
    Button,
    FileUpload,
    Loading,
  },
  data() {
    return {
      campaña: null,
      files: [],
      showDeallocationModal: false,
      filesAsignacion: [],
      processDeallocationBase: {
        campaña: "",
        assignment_code: "",
        updates_path: "",
        desassignment_path: "",
        campaign_2: "",
        assignment_path: "",
      },
      Loading: false,
      showDeallocationProcessButton: false,
      showDeallocationUploadButton: true,
      parametersBanks: null,
      parametersSantander: queryVariablesSantander[0],
      queriesSantander: queryVariablesSantander,
    };
  },

  watch: {
    openDeallocationModal(val) {
      if(val){
        this.filesSelectAsignacion();
      }
      this.openDeallocationModal == true
        ? (this.showDeallocationModal = true)
        : (this.openDeallocationModal = false);
    },
    processDeallocationBase: {
      deep: true,
      handler() {
        this.formDeallocationValidation();
      },
    },
  },
  methods: {
    async filesSelectAsignacion() {
      let paramsModal = {
        type: 'asignaciones',
        bucket_type: "output",
        bank: this.bankName,
        skip: 0,
        limit: 1000,
      };
      let response = await this.$store.dispatch("files/getFiles", paramsModal);
      this.filesAsignacion = response.data
        .sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        .map((el) => el.name);
    },
    hideDeallocationModal() {
      this.showDeallocationProcessButton = false;
      this.showDeallocationUploadButton = true;
      for (let i in this.processDeallocationBase) {
        this.processDeallocationBase[i] = "";
      }
      this.$emit("closeDeallocationModal");
    },
    filesChange(fileList) {
      fileList.files.forEach((file) => {
        this.files.push(file);
      });
    },
    clearFile() {
      this.files = [];
    },
    async filesDeallocationChange() {
      let data = new FormData();
      this.files.forEach((file) => {
        data.append("file", file);
      });
      const campaña = this.campaña;
      let result = await this.$store.dispatch("upload/uploadFilesSantander", {
        data,
        campaña,
        selectedOption: this.parametersSantander.value,
      });
      if (this.parametersSantander.value == "actualizaciones") {
        this.processDeallocationBase.updates_path = result.data.directory;
      } else if (this.parametersSantander.value == "desasignaciones") {
        this.processDeallocationBase.desassignment_path = result.data.directory;
      }
    },
    formDeallocationValidation() {
      let count = 0;
      for (let i in this.processDeallocationBase) {
        if (this.processDeallocationBase[i] != "") {
          count += 1;
        }
      }
      count == 3 ? (this.showDeallocationProcessButton = true) : "";
      count == 3 ? (this.showDeallocationUploadButton = false) : "";
    },
    async BaseDeallocationProcess() {
      this.Loading = true;
      let body = {};
      body.updates_path = this.processDeallocationBase.updates_path;
      body.assignment_path = this.processDeallocationBase.assignment_path;
      body.assignment_code = this.processDeallocationBase.assignment_code;
      body.desassignment_path = this.processDeallocationBase.desassignment_path;
      body.campaign_2 = this.processDeallocationBase.campaign_2;
      await this.$store.dispatch("processBase/postDeallocationBase", body);
      this.Loading = false;
      this.reload({ name: "ReportedFiles" });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-direction: column;
  &__title {
    color: #717274;
    margin-bottom: 0;
  }
  &__grid {
    display: flex;
    margin: 10px;
  }
}
.gmv-process-modal-form {
  &__inputs {
    margin: 10px;
  }
}
.process-button__content {
  display: flex;
  justify-content: right;
}
.gmv-uploadmodal-button {
  display: flex;
  justify-content: space-evenly;
  &__btn1 {
    background-color: $color-primary-company;
    height: 45px;
    border: 1px solid $color-primary-company;
    width: 150px;
    border-radius: 10px;
    margin: 10px;
  }
  &__btn1:hover {
    background-color: red;
    color: black;
    border: 1px solid $color-primary-company;
  }
}
.button-upload-files {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@include upload-button;
.gmv-process-modal-button {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
::v-deep .p-fileupload-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 45%;
}
::v-deep .p-fileupload .p-fileupload-content {
  padding: 4rem 4rem;
  border: 2px dashed #dee2e6;
  color: #495057;
  border-radius: 10px;
  &:hover {
    border: 2px dashed #b3282d;
  }
}
::v-deep .p-fileupload-choose:not(.p-disabled) {
  background: transparent;
  color: #b3282d;
  border-color: transparent;
  &:hover {
    background: transparent;
    color: #fd040c;
    border-color: transparent;
  }
}
::v-deep .p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
  padding: 0;
}
::v-deep .modal-footer {
  display: none;
}
::v-deep .el-upload__tip {
  font-size: 14px;
  color: #606266;
  margin-top: 7px;
}
</style>
